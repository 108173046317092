<template>
  <div></div>
</template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'getBannerQuery',

  watch: {
    banner() {
      this.$store.commit('graphqlData', { item: 'banner', result: this.banner });
    },
  },

  setup() {
    const store = useStore();

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
      cachePolicy: 'network-only',
    });

    const getBannerQuery = gql`
      query MyQuery($lang:Locale!) {
        banner(where: {id: "cm4tpl9pc482p07t35wx20bt8"}, locales: [$lang]) {
          visible
          text
        }
      }
    `;

    const { data: banner } = useQuery({
      query: getBannerQuery,
      variables,
    });

    return { banner };
  },
};
</script>
